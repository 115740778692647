import React, { useEffect, useState } from "react";
import styles from "../landing/Landing.module.css";
import logoAlsina from "../../assets/logo_alsina.png";
import QrScanner from "react-qr-scanner"; // npm install react-qr-scanner

const Landing = () => {
  const [valorInput, setValorInput] = useState("");
  const [qrCodeActive, setQrCodeActive] = useState(false);
  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function detectarCambioPantalla() {
      const anchoVentana = window.innerWidth;
      const notAcceptedDeviceElement = document.getElementsByClassName(
        styles.notAcceptedDevice
      )[0];

      if (anchoVentana >= 895) {
        setTimeout(() => {
          console.log("Muy grande");
          notAcceptedDeviceElement.style.display = "flex";
        }, 100);
      } else {
        notAcceptedDeviceElement.style.display = "none";
      }
    }

    function detectarDispositivoMovil() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        setIsMobile(true);
      }
    }

    window.addEventListener("resize", detectarCambioPantalla);
    detectarCambioPantalla();
    detectarDispositivoMovil();

    return () => {
      window.removeEventListener("resize", detectarCambioPantalla);
    };
  }, []);
  /////////////////////////////////PREVENT QUERY LEAK IN LOCALSTORAGE//////////////////////////////////////
  useEffect(() => {
    console.log("localstorage refreshed");
    const keepKeys = ["expiration", "referenceCode"];
    // Crear un objeto temporal para guardar los valores de las variables que queremos conservar
    let keepValues = {};
    // Recorrer todas las claves en localStorage
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      // Si la clave es una de las que queremos conservar, la guardamos
      if (keepKeys.includes(key)) {
        keepValues[key] = localStorage.getItem(key);
      }
    }
    // Limpiar todo el localStorage
    localStorage.clear();
    // Restaurar las variables que queremos conservar
    for (let key in keepValues) {
      localStorage.setItem(key, keepValues[key]);
    }
    console.log("Se han eliminado todas las claves excepto:", keepKeys);
  }, [localStorage.length]);
  /////////////////////////////////PREVENT QUERY LEAK IN LOCALSTORAGE//////////////////////////////////////

  function redirect() {
    console.log(valorInput);
    if (valorInput.length === 0) {
      alert("Introduce un código de referencia válido");
    } else {
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().split("T")[0];
      localStorage.setItem("expiration", currentDateString);

      localStorage.setItem("referenceCode", valorInput);
      var currentURL = window.location.href;
      var newURL = currentURL.replace(/\/[^\/]*$/, "/main");
      window.location.href = newURL;
    }
  }

  function changeLogMethod() {
    console.log("aa");

    if (isMobile) {
      setQrCodeActive(!qrCodeActive);
    }
  }

  const handleScan = (data) => {
    if (data) {
      console.log("Contenido del QR: ", data.text);
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().split("T")[0];
      localStorage.setItem("expiration", currentDateString);

      localStorage.setItem("referenceCode", data.text);
      var currentURL = window.location.href;
      var newURL = currentURL.replace(/\/[^\/]*$/, "/main");
      // alert(data.text);
      window.location.href = newURL;
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const requestCameraPermission = async () => {
    try {
      const result = await navigator.mediaDevices.getUserMedia({ video: true });
      if (result) {
        setCameraPermissionGranted(true);
      }
    } catch (error) {
      console.error("Error al solicitar permisos de cámara", error);
    }
  };

  return (
    <div>
      <div className={styles.notAcceptedDevice}>
        <div className={styles.notAcceptedDevicePopUP}>
          <p>
            <img
              className={styles.imageLogoPopUp}
              alt="logoAlsina"
              src={logoAlsina}
            />
          </p>
          <p>Esta aplicación solo es compatible con móvil o tablet</p>
        </div>
      </div>
      <nav className={styles.navBar}>
        <img src={logoAlsina} alt="logoAlsina" className={styles.logoNav} />
      </nav>
      <div className={`${styles.mainContainer} ${styles.ctr}`}>
        <div className={`${styles.referenceContainer} ${styles.ctr}`}>
          <div className={`${styles.referenceTopPart} ${styles.ctr}`}>
            <span className={styles.referenceTopText}>
              Revisa el estado de tus pedidos con unos simples clicks
            </span>
          </div>
          <div className={`${styles.referenceBottomPart} ${styles.ctr}`}>
            {qrCodeActive ? (
              cameraPermissionGranted ? (
                <div className={`${styles.contentArea} ${styles.ctr}`}>
                  <div className={styles.movingBar}></div>
                  <QrScanner
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    className={styles.qrScanner}
                    constraints={{
                      video: { facingMode: "environment" },
                    }}
                  />
                </div>
              ) : (
                <div className={`${styles.contentArea} ${styles.ctr}`}>
                  <button
                    onClick={requestCameraPermission}
                    className={styles.searchButtonRequestPermissions}
                  >
                    Permitir acceso a la cámara
                  </button>
                </div>
              )
            ) : (
              <div className={`${styles.contentArea} ${styles.ctr}`}>
                <span className={styles.topText}>Sigue tu obra</span>
                <input
                  id="refInput"
                  className={styles.inputRef}
                  type="text"
                  placeholder="Introduce el Número de referencia"
                  onChange={(e) => setValorInput(e.target.value)}
                />

                <button className={styles.searchButton} onClick={redirect}>
                  Buscar
                </button>
              </div>
            )}
          </div>
          <div
            // onClick={changeLogMethod}
            className={`${styles.referenceChangeLogin} ${styles.ctr}`}
          >
            <div className={styles.sliderContainer}>
              <span>¿Usar QR?</span>
              <div>
                <label className={styles.switch}>
                  <input type="checkbox" onClick={changeLogMethod} />
                  <span className={styles.slider}></span>
                </label>
              </div>
            </div>
            {/* {qrCodeActive ? (
                <span className={styles.referenceBotomText}>
                  Sigue tu obra usando un código de referencia
                </span>
              ) : (
                <span className={styles.referenceBotomText}>
                  Sigue tu obra usando un código QR
                </span>
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
